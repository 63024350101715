<template>
  <vs-popup title="Kontrak Payment History" class="sm:popup-w-70" :active="isActive" v-on:update:active="emitModalIsActive">
    <div>
      <vs-divider>Payment History</vs-divider>
      <vs-table :data="paymentHistory" stripe>
        <template slot="thead">
          <vs-th class="whitespace-no-wrap">#</vs-th>
          <vs-th class="whitespace-no-wrap">No Trx</vs-th>
          <vs-th class="whitespace-no-wrap">No Jurnal Payment</vs-th>
          <vs-th class="whitespace-no-wrap">No Invoice</vs-th>
          <vs-th class="whitespace-no-wrap">Tgl Bayar</vs-th>
          <vs-th class="whitespace-no-wrap">Jml Bayar</vs-th>
          <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
            <vs-td class="whitespace-no-wrap">{{ index + 1 }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.no_trx }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.no_jurnal_payment }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.no_invoice }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.tgl_bayar }}</vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ idr(item.jml_bayar) }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.keterangan }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </vs-popup>
</template>

<script>
import KontrakRepository from '@/repositories/marketing/kontrak/kontrak-repository'

export default {
  name: 'KontrakPaymentHistory',
  props: ['isActive', 'idKontrak'],
  data () {
    return {
      isLoading: false,
      paymentHistory: []
    }
  },
  watch: {
    isActive (active) {
      if (active) {
        this.getPaymentHistory()
      }
    }
  },
  methods: {
    getPaymentHistory () {
      this.isLoading = true

      const idKontrak = this.idKontrak
      KontrakRepository.getPaymentHistory(idKontrak)
        .then(response => {
          this.paymentHistory = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    },

    idr (value, decimal = 2) {
      return this.$options.filters.idr(value, decimal)
    }
  }

}
</script>
