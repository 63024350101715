const getDefaultState = () => {
  return {
    activeTab: 0,
    kontrak: {}
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    SET_ACTIVE_TAB (state, tabIndex) {
      state.activeTab = tabIndex
    },
    SET_KONTRAK (state, payload) {
      state.kontrak = payload
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },

  actions: {}
}
