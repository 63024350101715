<template>
  <div>
    <vx-card :title="'#' + (kontrak.no_kontrak || '')" class="mb-3" collapse-action :is-refresh="isLoading">
      <div class="vx-row">
        <div class="vx-col">
          <div class="img-container mb-4">
            <img alt="" class="w-32" src="@/assets/svg/contract.svg"/>
          </div>
        </div>
        <div class="vx-col flex-1">
          <table class="table-info">
            <tr>
              <td class="font-semibold">Booking Asal</td>
              <td>: #{{ kontrak.no_booking_asal }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Tgl. Kontrak</td>
              <td>: {{ kontrak.tgl }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Tgl. Batal</td>
              <td>: {{ kontrak.tgl_batal || '-' }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Status</td>
              <td>: <span class="inline-flex items-center whitespace-no-wrap justify-center p-1 text-xs text-white rounded-full" :class="['bg-' + kontrak.status_kontrak_color]">{{ kontrak.status_kontrak }}</span></td>
            </tr>
            <tr>
              <td class="font-semibold">Janis Data</td>
              <td>: {{ kontrak.jenis_data }}</td>
            </tr>
          </table>
        </div>
        <div class="vx-col flex-1">
          <table class="table-info">
            <tr>
              <td class="font-semibold">Nama Pemilik</td>
              <td>: {{ kontrak.nama_customer }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Nama Pemesan</td>
              <td>: {{ kontrak.nama_pemesan }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Nama Marketing</td>
              <td>: {{ kontrak.nama_staf }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Masa Garansi</td>
              <td>: {{ kontrak.masa_garansi }} Hari</td>
            </tr>
            <tr>
              <td class="font-semibold">Created At</td>
              <td>: {{ kontrak.created_at }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="vx-row mt-3">
        <div class="vx-col flex space-x-3">
          <vs-button type="filled" color="primary" size="small" class="px-3" icon-pack="feather" icon="icon-printer" :disabled="!kontrak.status_kontrak || kontrak.status_kontrak === 'CANCEL'" @click="printPpjb">Print PPJB</vs-button>
          <vs-button type="filled" color="primary" size="small" class="px-3" icon-pack="feather" icon="icon-printer" :disabled="!kontrak.status_kontrak || !kontrak.id_stc || kontrak.status_kontrak === 'CANCEL'" @click="printStc">Print STC</vs-button>
          <vs-button type="filled" color="primary" size="small" class="px-3" icon-pack="feather" icon="icon-credit-card" @click="showModalPaymentHistory">Payment History</vs-button>
          <vs-button type="filled" color="danger" size="small" class="px-3" icon-pack="feather" icon="icon-x" @click="modalKontrakBatal.active = true" :disabled="!kontrak.status_kontrak || kontrak.id_jurnal === null || kontrak.status_kontrak === 'CANCEL'">Batalkan Kontrak</vs-button>
          <vs-button type="flat" color="primary" size="small" class="px-3"  icon-pack="feather" icon="icon-refresh-cw" @click="getKontrak">Refresh</vs-button>
        </div>
      </div>
    </vx-card>

    <vx-card v-if="isDataInited">
      <vs-tabs alignment="fixed" v-model="activeTab">
        <vs-tab label="Customer">
          <TabCustomer :isActive="activeTab === 0"/>
        </vs-tab>
        <vs-tab label="Properti">
          <TabProperti :isActive="activeTab === 1"/>
        </vs-tab>
        <vs-tab label="Kontrak">
          <TabKontrak :isActive="activeTab === 2"/>
        </vs-tab>
        <vs-tab label="Angsuran">
          <TabAngsuran :isActive="activeTab === 3"/>
        </vs-tab>
        <vs-tab label="Pengajuan KPR">
          <TabProsesKpr :isActive="activeTab === 4"/>
        </vs-tab>
        <vs-tab label="Refund">
          <TabRefund :isActive="activeTab === 5"/>
        </vs-tab>
        <vs-tab label="Penghuni">
          <TabPenghuni :isActive="activeTab === 6"/>
        </vs-tab>
        <vs-tab label="Dokumen Customer">
          <TabDokumenCustomer :isActive="activeTab === 7"/>
        </vs-tab>
        <vs-tab label="Hadiah">
          <TabHadiah :isActive="activeTab === 8"/>
        </vs-tab>
        <vs-tab label="Komisi">
          <TabKomisi :isActive="activeTab === 9"/>
        </vs-tab>
      </vs-tabs>
    </vx-card>

    <!--modals-->
    <KontrakBatal :isActive.sync="modalKontrakBatal.active"
                               :idKontrak="modalKontrakBatal.idKontrak"
                               @success="onSubmit"/>

    <KontrakPaymentHistory :isActive.sync="modalPaymentHistory.active"
                             :idKontrak="modalPaymentHistory.idKontrak"/>
  </div>
</template>

<script>
import KontrakRepository from '@/repositories/marketing/kontrak/kontrak-repository'
import moduleKontrakDetail from '@/store/modules/marketing/kontrak/kontrak-detail.store'
import printoutMixin from '@/utilities/mixins/printout-mixin'
import KontrakPaymentHistory from '@/views/pages/marketing/kontrak/KontrakPaymentHistory.vue'

export default {
  name: 'KontrakDetail',
  components: {
    KontrakPaymentHistory,
    TabCustomer: () => import('@/views/pages/marketing/kontrak/tabs-detail/kontrak-customer/TabCustomer.vue'),
    TabProperti: () => import('@/views/pages/marketing/kontrak/tabs-detail/kontrak-properti/TabProperti.vue'),
    TabKontrak: () => import('@/views/pages/marketing/kontrak/tabs-detail/kontrak/TabKontrak.vue'),
    TabAngsuran: () => import('@/views/pages/marketing/kontrak/tabs-detail/kontrak-angsuran/TabAngsuran.vue'),
    TabProsesKpr: () => import('@/views/pages/marketing/kontrak/tabs-detail/kontrak-proses-kpr/TabProsesKpr'),
    TabRefund: () => import('@/views/pages/marketing/kontrak/tabs-detail/kontrak-refund/TabRefund.vue'),
    TabPenghuni: () => import('@/views/pages/marketing/kontrak/tabs-detail/kontrak-penghuni/TabPenghuni.vue'),
    TabDokumenCustomer: () => import('@/views/pages/marketing/kontrak/tabs-detail/kontrak-dokumen-customer/TabDokumenCustomer.vue'),
    TabHadiah: () => import('@/views/pages/marketing/kontrak/tabs-detail/kontrak-hadiah/TabHadiah.vue'),
    TabKomisi: () => import('@/views/pages/marketing/kontrak/tabs-detail/kontrak-komisi/TabKomisi.vue'),
    KontrakBatal: () => import('@/views/pages/marketing/kontrak/KontrakBatal')
  },
  mixins: [printoutMixin],
  mounted () {
    this.initData()
  },
  computed: {
    storeKontrakDetail () {
      return this.$store.state.marketing.kontrakDetail
    },
    activeTab: {
      get () {
        return this.storeKontrakDetail.activeTab
      },
      set (value) {
        this.$store.commit('marketing/kontrakDetail/SET_ACTIVE_TAB', value)
      }
    },
    kontrak: {
      get () {
        return this.storeKontrakDetail.kontrak
      },
      set (value) {
        this.$store.commit('marketing/kontrakDetail/SET_KONTRAK', value)
      }
    }
  },
  data () {
    return {
      isDataInited: false,
      isLoading: false,
      modalKontrakBatal: {
        active: false,
        idKontrak: this.$route.params.idKontrak
      },
      modalPaymentHistory: {
        idKontrak: null,
        active: false
      }
    }
  },
  methods: {
    onSubmit () {
      this.initData()
    },

    initData () {
      this.getKontrak()
    },

    getKontrak () {
      this.isLoading = true

      const idKontrak = this.$route.params.idKontrak
      KontrakRepository.show(idKontrak)
        .then(response => {
          this.kontrak = response.data.data
          this.isDataInited = true
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    showModalPaymentHistory () {
      this.modalPaymentHistory.idKontrak = this.$route.params.idKontrak
      this.modalPaymentHistory.active = true
    },

    printPpjb () {
      this.showPrintOutFile('PPJB', { id: this.$route.params.idKontrak })
    },

    printStc () {
      this.showPrintOutFile('BAST_STC', { id: this.kontrak.id_stc })
    }
  },
  beforeCreate () {
    this.$store.registerModule(['marketing', 'kontrakDetail'], moduleKontrakDetail)
  },
  beforeDestroy () {
    this.$store.commit('marketing/kontrakDetail/RESET_STATE')
    this.$store.unregisterModule(['marketing', 'kontrakDetail'])
  }
}
</script>

<style scoped lang="scss">
table.table-info {
  td {
    vertical-align: top;
    min-width: 140px;
    padding-bottom: .5rem;
    word-break: break-all;
  }
}
</style>
